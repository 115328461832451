<template>
  <div class="account-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Account information-->
      <div v-if="!loading">
        <!-- Account detail-->
        <div class="row">
          <!--Account Name-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">Name:</div>
              <div class="account__item__text">
                <template v-if="account.Name">{{ account.Name }}</template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account DBA-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">DBA:</div>
              <div class="account__item__text">
                <template v-if="account.Name">{{ account.DBA }}</template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account Phone-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">Phone:</div>
              <div class="account__item__text">
                <template v-if="account.Phone">
                  <a :href="'tel:' + account.Phone">
                    {{ account.Phone }}
                  </a>
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account Email-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">Email:</div>
              <div class="account__item__text">
                <template v-if="account.Email">
                  <a :href="'mailto:' + account.Email">
                    {{ account.Email }}
                  </a>
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
        </div>
        <!--Account Billing Address-->
        <div v-if="account.BillingAddress" class="row">
          <div class="col-12 mt-3 mb-2">
            <b>Billing Address:</b>
          </div>
          <!--Account Billing Address Street-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">Street:</div>
              <div class="account__item__text">
                <template v-if="account.BillingAddress.Street">
                  {{ account.BillingAddress.Street }}
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account Billing Address State-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">State:</div>
              <div class="account__item__text">
                <template v-if="account.BillingAddress.State">
                  {{ account.BillingAddress.State }}
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account Billing Address Postal Code-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">Postal Code:</div>
              <div class="account__item__text">
                <template v-if="account.BillingAddress.PostalCode">
                  {{ account.BillingAddress.PostalCode }}
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account Billing Address Country-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">Country:</div>
              <div class="account__item__text">
                <template v-if="account.BillingAddress.Country">
                  {{ account.BillingAddress.Country }}
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
          <!--Account Billing Address City-->
          <div class="col-12 col-lg-4">
            <div class="account__item">
              <div class="account__item__title">City:</div>
              <div class="account__item__text">
                <template v-if="account.BillingAddress.City">
                  {{ account.BillingAddress.City }}
                </template>
                <template v-else>—</template>
              </div>
            </div>
          </div>
        </div>

        <!-- Account Commissions -->
        <div class="row mt-3 mb-2">
          <div class="col-12 m-0">
            <div class="accordion" @click="showCommissions()">
              <b-icon-arrow-right-circle-fill
                v-if="!showCommissionsTable"
                class="accordion__icon"
              />
              <b-icon-arrow-down-circle-fill v-else class="accordion__icon" />
              <h3 class="accordion__title">Commissions</h3>
            </div>
          </div>
        </div>
        <!--Search-->
        <div class="row m-0" v-if="showCommissionsTable && Commissions.length">
          <b-input-group size="sm" class="mb-3 col-12 col-lg-3 p-0">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Enter search text"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <div class="col-2 col-lg-3 p-0 mb-3">
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div v-if="showCommissionsTable" class="row">
          <b-table
            class="table col-12 m-0"
            hover
            striped
            :items="Commissions"
            :fields="fieldsCommissions"
            :filter="filter"
            :filter-included-fields="filterOn"
            responsive
            :current-page="currentPage"
            :per-page="perPage"
          >
            <!--ISO Rep column-->
            <template #cell(ISORep)="row">
              <div v-if="row.item.ISORep">
                {{ row.item.ISORep.FirstName ? row.item.ISORep.FirstName : '' }}
                {{ row.item.ISORep.LastName ? row.item.ISORep.LastName : '' }}
              </div>
            </template>
            <!--Account Name Router Link column-->
            <template #cell(Account)="row">
              <div v-if="row.item.Account">
                {{ row.item.Account.Name ? row.item.Account.Name : '' }}
              </div>
            </template>
            <template #cell(Commission)="row">
              <div v-if="row.item.Commission">
                {{ row.item.Commission + '%' }}
              </div>
            </template>
          </b-table>
          <div v-if="!Commissions.length" class="col-12 flex-center">
            <p class="text-center p-5">No records found.</p>
          </div>
          <!--Table Pagination-->
          <div class="col-12 flex-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>

        <div class="row">
          <!--Owners -->
          <div v-if="account.Owners" class="col-12 mt-3 mb-2">
            <b>Owners:</b>
          </div>
          <!--Owners Tabs-->
          <div
            v-if="account.Owners && account.Owners.length"
            class="col-12 m-0"
          >
            <b-card no-body>
              <b-tabs pills card>
                <template v-for="owner in account.Owners">
                  <b-tab
                    :title="owner.Name"
                    :key="owner.Name"
                    class="pt-3 pb-3"
                  >
                    <b-card-text>
                      <div class="row">
                        <!--Owner First Name-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">First Name:</div>
                            <div class="account__item__text">
                              <template v-if="owner.FirstName">
                                {{ owner.FirstName }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Last Name-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Last Name:</div>
                            <div class="account__item__text">
                              <template v-if="owner.LastName">
                                {{ owner.LastName }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Phone-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Phone:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Phone">
                                <a :href="'tel:' + owner.Phone">
                                  {{ owner.Phone }}
                                </a>
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Mobile-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Mobile:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Mobile">
                                <a :href="'tel:' + owner.Mobile">
                                  {{ owner.Mobile }}
                                </a>
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Emails-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Emails:</div>
                            <div class="account__item__text">
                              <template
                                v-if="owner.Emails && owner.Emails.length"
                              >
                                <div
                                  v-for="(email, index) in owner.Emails"
                                  :key="index"
                                >
                                  <a :href="'mailto:' + email">
                                    {{ email }}
                                  </a>
                                </div>
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--Owner Address-->
                      <div v-if="owner.Address" class="row">
                        <div class="col-12 mt-3 mb-2">
                          <b>Address:</b>
                        </div>
                        <!--Owner Address Street-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Street:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Address.Street">
                                {{ owner.Address.Street }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Address State-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">State:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Address.State">
                                {{ owner.Address.State }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Address Postal Code-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Postal Code:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Address.PostalCode">
                                {{ owner.Address.PostalCode }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Address Country-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">Country:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Address.Country">
                                {{ owner.Address.Country }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                        <!--Owner Address City-->
                        <div class="col-12 col-lg-4">
                          <div class="account__item">
                            <div class="account__item__title">City:</div>
                            <div class="account__item__text">
                              <template v-if="owner.Address.City">
                                {{ owner.Address.City }}
                              </template>
                              <template v-else>—</template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </template>
              </b-tabs>
            </b-card>
          </div>
          <!--Owners is empty-->
          <div v-else class="col-12 flex-center m-0">
            <p class="text-center p-5">Owners is empty.</p>
          </div>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './AccountPage.scss'
import AccountsService from '@/services/account/account.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'AccountPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Account inforamtion',
    titleTemplate: '%s | ISO Portal | Nexi',
  },
  data() {
    return {
      loading: false,
      title: 'Account information',
      account: {},
      showCommissionsTable: false,
      Commissions: [],
      fieldsCommissions: [
        {
          key: 'Name',
          label: 'Contract №',
          sortable: true,
        },
        {
          key: 'Type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'Commission',
          label: 'Commission, %',
          sortable: true,
        },
        {
          key: 'TotalCommission',
          label: 'Total Commission',
          sortable: true,
        },
        {
          key: 'AmountPaid',
          label: 'Amount Paid',
          sortable: true,
        },
        {
          key: 'Clawback',
          label: 'Clawback',
          sortable: true,
        },
        {
          key: 'Balance',
          label: 'Balance',
          sortable: true,
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getAccount()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getAccount()
    } else this.$system.firstLoading()
  },
  methods: {
    async getAccount() {
      try {
        this.loading = true
        const response = await AccountsService.getAccount(
          this.$system.user,
          this.$route.params.id,
        )
        if (response && response.status == 'success' && response.body) {
          this.account = response.body
          this.loading = false
          if (response.body.Commissions && response.body.Commissions.length) {
            this.Commissions = response.body.Commissions
            this.totalRows = this.Commissions.length
            if (response.body.isMain) {
              let field = {
                key: 'ISORep',
                label: 'ISO REP',
                sortable: false,
              }
              this.fieldsCommissions.push(field)
            }
          }
        } else {
          this.$router.push('/submissions')
          this.loading = false
        }
      } catch (err) {
        this.$router.push('/submissions')
        this.loading = false
      }
    },
    showRepayments() {
      this.repayments = !this.repayments
    },
    showDisbursements() {
      this.disbursements = !this.disbursements
    },
    showCommissions() {
      this.showCommissionsTable = !this.showCommissionsTable
    },
  },
}
</script>
